import React, { useContext, useState, useEffect } from "react"
import $ from 'jquery'
import _ from 'lodash'
import DatePicker from 'react-datetime'
import axios from 'axios'
import NumberFormat from 'react-number-format';
import { PlaidLink } from "react-plaid-link"
import Layout from "../components/layout"
import { navigate } from "gatsby"
import { AppStateContext } from "../contexts/AppStateContext"
import analytics from "../lib/analytics"
import LocationSearchInput from "../components/LocationSearchInput"
import settings from "../lib/settings"
import { useInterval } from "../hooks/useInterval"
import Loader from "../components/Loader"

let axiosInstance = null;
analytics.init()

const IndexPage = () => {
  const appState = useContext(AppStateContext);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState();
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState();
  const [legalBusinessName, setLegalBusinessName] = useState("");
  const [legalBusinessNameError, setLegalBusinessNameError] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [mobileError, setMobileError] = useState();
  const [tradeName, setTradeName] = useState("");
  const [tradeNameError, setTradeNameError] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState();
  const [address1, setAddress1] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState();
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState();
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState();
  const [einNumber, setEinNumber] = useState("");
  const [businessEinError, setBusinessEinError] = useState();
  const [hasLease, setHasLease] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [leaseAmount, setLeaseAmount] = useState("");
  const [leaseAmountError, setLeaseAmountError] = useState("");
  const [ownerName, setOwnerName] = useState("")
  const [ownerNameError, setOwnerNameError] = useState("")
  const [ownerMobile, setOwnerMobile] = useState("")
  const [ownerMobileError, setownerMobileError] = useState("")
  const [unitNumber, setUnitNumber] = useState("")
  const [unitNumberError, setUnitNumberError] = useState("")
  const [bankName, setBankName] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordError, setPasswordError] = useState();
  const [selectedFormIndex, setSelectedFormIndex] = useState(0);
  const [quickbooksSetup, setQuickbooksSetup] = useState(false);
  // const [usePlaid, setUsePlaid] = useState(false);
  // const [useQuickbooks, setUseQuickbooks] = useState(false);
  const [quickbooksDone, setQuickbooksDone] = useState(false);
  const [loader, setLoader] = useState(false)
  // const [profileUuid, setProfileUuid] = useState();

  // State vars to track the state of forms
  const [completedOnboardOne, setCompletedOnboardOne] = useState(false);
  const [completedOnboardTwo, setCompletedOnboardTwo] = useState(false);
  const [completedOnboardThree, setCompletedOnboardThree] = useState(false);
  const [completedOnboardFour, setCompletedOnboardFour] = useState(false);

  useInterval(() => {
    const qBD = localStorage.getItem('quickbooks',false);
    if (qBD === true || qBD === 'true'){
      setQuickbooksDone(true)
      localStorage.setItem('quickbooks',false);
      setLoader(false)
      // console.log('INTUIT: quickbooks says, ', qBD)
    }
    if (window.intuit===undefined) {
      appendScript("https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js")
      appendScript("https://js.appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js")
      // console.log('INTUIT',12)cd a
    }else if (!quickbooksSetup|| window.intuit.ipp.anywhere!=='undefined' ) { //
      initializeQuickbooks()
      // console.log('INTUTI', 13)
    }
  }, quickbooksDone ? null : 2000);

  useEffect(() => {
    // navigate('/dashboard');
    // setCompletedOnboardOne(true);
    // switchToFormTab(2);
    closePopup();
    initAxiosInstance();
    // navigate('/account')
    // appendScript("https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js")
    // appendScript("https://js.appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js")
    if (appState.profileUuid.value && !window.location.href.includes('?popup=close')) {
      navigate('/logout')
    }
  }, [])

  // useEffect(()=> {
  //   if(window.intuit !== undefined){
  //     initializeQuickbooks();
  //   }
  //   // console.log("INTUIT: QB js loaded, ", window.intuit)
  // }, [])

  const initializeQuickbooks = () => {
    if (window.intuit!==undefined && window.intuit!==null && !quickbooksSetup) {
      const baseUrl = settings.get('GATSBY_DEBIE_API_BASE_URI')
      const fe_url = settings.get('GATSBY_DEBIE_FRONTEND_BASE_URI')
      // console.log("INTUIT: QB setup called, ", window.intuit)
      window.intuit.ipp.anywhere.setup({
        grantUrl: baseUrl + '/api/v3/connectToQuickbooks/?url='+fe_url ,
        datasources: {
          quickbooks : true,
          payments : true
        },
        paymentOptions:{
          intuitReferred : true
        }
      })
      // console.log("INTUIT: QB setup done:", window.intuit.ipp.anywhere)
      setQuickbooksSetup(true);
    }
  }

  useEffect(()=> {
    injectButton()
  }, [quickbooksSetup])

  const injectButton = () => {
    let buttonContainer = document.getElementById("intuButton")
    if (window.intuit.ipp.anywhere && quickbooksSetup){
      if(buttonContainer!==null && buttonContainer!==undefined && buttonContainer.childElementCount <1){
            const connectToIntuit = document.createElement('ipp:connectToIntuit');
            buttonContainer.appendChild(connectToIntuit);
            // console.log("INTUIT: QB button intialized", buttonContainer)
      }
    }
    return buttonContainer
  }

  const closePopup = () => {
    const fe_url = settings.get('GATSBY_DEBIE_FRONTEND_BASE_URI')
    if (window.opener ) {
      const flag = window.location.href.includes(fe_url + '?popup=close') || window.location.href.includes(fe_url+ '/?popup=close')
      if (flag){
        setLoader(true)
        onboardActiveRequest();
      } else {
        window.close()
      }
    } else {
        localStorage.setItem('quickbooks',false);
    }
  }

  const appendScript = (scriptToAppend) => {
    const script = window.document.createElement("script");
    script.src = scriptToAppend;
    script.type = "text/javascript"
    script.async = true;
    window.document.body.appendChild(script);
  }

  const handleQuickbooksClick = () => {
    localStorage.setItem('profile_uuid', appState.profileUuid.value)
    if(window.intuit!==undefined && quickbooksSetup) {
      // console.log(1)
      let intuitContainer = document.getElementsByClassName("intuitPlatformConnectButton")[0]
      if (intuitContainer){
        intuitContainer.click()
      }else{
        window.open(window.intuit.ipp.anywhere.grantUrl, 'popUpWindow','height=400,width=800')
        // const bc = injectButton()
        // console.log("INTUIT", bc, bc.children[0])
        // bc.children[0].click()
        // intuitContainer = document.getElementsByClassName("intuitPlatformConnectButton")[0]
        // if(intuitContainer){
        //   intuitContainer.click()
        // }
        // console.log('INTUIT CONTAINERS', document.getElementsByClassName("intuitPlatformConnectButton"))
      }
      // buttonContainer.children("a")[0].href
      // setUseQuickbooks(true)
      // if(useQuickbooks){
      // }
    }else if (window.intuit===undefined) {
      appendScript("https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js")
      appendScript("https://js.appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js")
      // console.log(2)
    }else if (!quickbooksSetup || window.intuit.ipp.anywhere!=='undefined') {
      initializeQuickbooks()
      // console.log(3)
    }

  }

  const initAxiosInstance = () => {
    if (!axiosInstance) {
      axiosInstance = axios.create({
        baseURL: settings.get('GATSBY_DEBIE_API_BASE_URI'),
        timeout: 10000,
        // headers: {'X-Custom-Header': 'foobar'}
      });
    }
  }

  const onboardOneRequest = () => {
    if (completedOnboardOne) {
      // console.log("Already Completed Onboard-1");
      switchToFormTab(2);
      return;
    }

    const onboardOneErrors = getOnboardOneErrors();
    if (onboardOneErrors) {
      displayValidationErrors(onboardOneErrors);
      return;
    }

    initAxiosInstance();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: businessEmail,
      // phone: mobile, //Should be optional
      legal_business_name: legalBusinessName,
      trade_name: tradeName,
      url: window.location.href
    }
    analytics.track('onboard-1', _.pick(data, ['first_name', 'last_name', 'email', 'legal_business_name', 'trade_name']))
    axiosInstance.post('/onboard-1/', data)
      .then(function (response) {
        // handle success
        if (response.data && response.data.uuid) {
          appState.profileUuid.set(response.data.uuid);
          // Clear Errors
          clearValidatonErrors();
          setCompletedOnboardOne(true);
          setCompletedOnboardTwo(true);
          switchToFormTab(3);
        } else {
          console.error("Invalid Response Received");
        }

      })
      .catch(function (error) {
        console.log(error);
        // handle error
        if (error.response && error.response.status && error.response.status === 400) {
          displayValidationErrors(error.response.data);
        }
        // switchToFormTab(2);
      })
  }

  const getOnboardOneErrors = () => {
    let errors = {};
    let foundError = false;
    // Check rquired Fields
    if (firstName.trim() === "") {
      foundError = true;
      errors.first_name = "First name is required";
    }
    if (lastName.trim() === "") {
      foundError = true;
      errors.last_name = "Last name is required";
    }
    if (businessEmail.trim() === "") {
      foundError = true;
      errors.email = "Email is required";
    }
    if (legalBusinessName.trim() === "") {
      foundError = true;
      errors.legal_business_name = "Legal business name is required";
    }
    if (tradeName.trim() === "") {
      foundError = true;
      errors.trade_name = "Trade name is required";
    }
    if (foundError) {
      return errors;
    } else {
      return null;
    }
  }

  const onboardTwoRequest = () => {
    if (completedOnboardTwo) {
      // console.log("Already Completed Onboard-2");
      if (!completedOnboardThree && hasLease) {
        onboardThreeRequest();
      } else {
        switchToFormTab(3);
      }
      return;
    }

    const onboardTwoErrors = getOnboardTwoErrors();
    if (onboardTwoErrors) {
      displayValidationErrors(onboardTwoErrors);
      return;
    }

    initAxiosInstance();
    const data = {
      business_address: address1,
      zip_code: pincode,
      city:city,
      state:state,
      profile_uuid: appState.profileUuid.value,
      business_ein: einNumber, //Should be optional
      legal_business_name: legalBusinessName,
      unit_number: unitNumber //Should be optional
    }
    if (typeof window.dataLayer !== `undefined`) {
      window.dataLayer.push({'event': 'Onboard-2'})
    }
    analytics.track('onboard-2', _.pick(data, ['business_address', 'zip_code', 'profile_uuid']))
    // console.log(data);
    axiosInstance.post('/onboard-2/', data)
      .then(function (response) {
        // handle success
        // Clear Errors
        clearValidatonErrors();
        setCompletedOnboardTwo(true);
        // Send request to onboard-3 if there is a lease
        if (hasLease) {
          onboardThreeRequest();
        } else {
          switchToFormTab(3);
        }
      })
      .catch(function (error) {
        console.log(error);
        // handle error
        if (error.response && error.response.status && error.response.status === 400) {
          displayValidationErrors(error.response.data);
        }
        //  switchToFormTab(3)
      })
  }

  const getOnboardTwoErrors = () => {
    let errors = {};
    let foundError = false;
    // Check rquired Fields
    if (address1.trim() === "") {
      foundError = true;
      errors.business_address = "Business address is required";
    }
    if (pincode.trim() === "") {
      foundError = true;
      errors.zip_code = "Zip code is required";
    }
    if (city.trim() === "") {
      foundError = true;
      errors.city = "City is required";
    }
    if (state.trim() === "") {
      foundError = true;
      errors.state = "State is required";
    }
    if (foundError) {
      return errors;
    } else {
      return null;
    }
  }

  const onboardThreeRequest = () => {
    if (completedOnboardThree) {
      // console.log("Already Completed Onboard-3");
      switchToFormTab(3);
      return;
    }

    const onboardThreeErrors = getOnboardThreeErrors();
    if (onboardThreeErrors) {
      displayValidationErrors(onboardThreeErrors);
      return;
    }

    initAxiosInstance();
    const data = {
      lease_start_date: startDate ? startDate.format('YYYY-MM-DD') : "",
      monthly_lease_amount: leaseAmount,
      profile_uuid: appState.profileUuid.value,
      legal_business_name: legalBusinessName,
      owner_name:ownerName,
      owner_mobile:ownerMobile
    }
    if (typeof window.dataLayer !== `undefined`) {
      window.dataLayer.push({'event': 'Onboard-3'})
    }
    analytics.track('onboard-3', _.pick(data, ['monthly_lease_amount', 'lease_start_date']))
    axiosInstance.post('/onboard-3/', data)
      .then(function (response) {
        // handle success
        // Clear Errors
        clearValidatonErrors();
        setCompletedOnboardThree(true);
        switchToFormTab(3);
      })
      .catch(function (error) {
        console.log(error);
        // handle error
        if (error.response && error.response.status && error.response.status === 400) {
          displayValidationErrors(error.response.data);
        }
        //  switchToFormTab(3);
      })
  }

  const getOnboardThreeErrors = () => {
    let errors = {};
    let foundError = false;
    // Check rquired Fields
    if (!startDate) {
      foundError = true;
      errors.lease_start_date = "Lease start date is required";
    }
    if (leaseAmount.trim() === "") {
      foundError = true;
      errors.lease_amount = "Lease Amount is required";
    }
    if (foundError) {
      return errors;
    } else {
      return null;
    }
  }

  const onboardActiveRequest = () => {
    initAxiosInstance();
    const urlParams = new URLSearchParams(window.location.href);
    const data = {
      profile_uuid: localStorage.getItem('profile_uuid', appState.profileUuid.value),
      code: urlParams.get('code'),
      state: urlParams.get('state'),
      realm_id: urlParams.get('realmId')
    }
    axiosInstance.post('/onboard-active/', data)
      .then(function (response) {
        // handle success
        localStorage.setItem('profile_uuid', null)
        localStorage.setItem('quickbooks', true);
        window.close()
        // navigate('/dashboard');
        // Clear Errors
      })
      .catch(function (error) {
        console.log(error);
        // handle error
        if (error.response && error.response.status && error.response.status === 400) {
          displayValidationErrors(error.response.data);
        }
        window.close()
      })
  }

  const onboardFourRequest = () => {
    if (completedOnboardFour) {
      // console.log("Already Completed Onboard-4");
      // switchToFormTab(3);
      return;
    }
    const onboardFourErrors = getOnboardFourErrors();
    if (onboardFourErrors) {
      displayValidationErrors(onboardFourErrors);
      return;
    }
    initAxiosInstance();
    const data = {
      profile_uuid: appState.profileUuid.value,
      password: password1.trim()
    }
    axiosInstance.post('/onboard-4/', data)
      .then(function (response) {
        // handle success
        navigate('/dashboard');
        // Clear Errors
        clearValidatonErrors();
        setCompletedOnboardFour(true);
      })
      .catch(function (error) {
        console.log(error);
        // handle error
        if (error.response && error.response.status && error.response.status === 400) {
          displayValidationErrors(error.response.data);
        }
      })
  }

  const getOnboardFourErrors = () => {
    let passwordOne = password1.trim();
    let passwordTwo = password2.trim();
    if (passwordOne === "" || passwordTwo === "") {
      return {
        password: "Password field is required "
      }
    } else if (passwordOne !== passwordTwo) {
      return {
        password: "Passwords must match "
      }
    } else {
      return null
    }
  }

  const getOnboardedAccessTokenRequest = (public_token, institution_id, institution_name) => {
    initAxiosInstance();
    const data = {
      public_token,
      institution_id,
      institution_name
    }
    axiosInstance.post(`/get_onboarded_access_token/${appState.profileUuid.value}`, data)
      .then(function (response) {
        // handle success
        // console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  const plaidGetInstitutionRequest = (institution_id) => {
    const axiosInstance = axios.create({
      baseURL: settings.get('GATSBY_PLAID_API_BASE_URI'),
      timeout: 10000,
      // headers: {'X-Custom-Header': 'foobar'}
    });
    const data = {
      institution_id,
      public_key: "394a4d8c7fa48e7ef8881ec5ac6b8e",
      options: {
        include_optional_metadata: true
      }
    }
    axiosInstance.post(`/institutions/get_by_id`, data)
      .then(function (response) {
        // handle success
        if (response.data && response.data.institution) {
          appState.institution.set(response.data.institution);
        }

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  const displayValidationErrors = (errorsObject) => {
    const { first_name, last_name, legal_business_name, trade_name, email, /*phone,*/ business_address, zip_code, city, state, lease_start_date, lease_amount, password, business_ein } = errorsObject;
    setFirstNameError(first_name);
    setLastNameError(last_name);
    setLegalBusinessNameError(legal_business_name);
    setTradeNameError(trade_name);
    setBusinessEmailError(email);
    // setMobileError(phone);
    setAddress1Error(business_address);
    setPincodeError(zip_code);
    setStartDateError(lease_start_date);
    setLeaseAmountError(lease_amount);
    setPasswordError(password);
    setBusinessEinError(business_ein);
    setState(state);
    setCity(city);
  }

  const clearValidatonErrors = () => {
    displayValidationErrors({});
  }

  const switchToFormTab = (tabNumber) => {
    if(tabNumber-1 !== selectedFormIndex){
      $(`#myTab li:nth-child(${tabNumber}) a`).tab('show');
      setSelectedFormIndex(tabNumber - 1);
      window.scrollTo(0, 0);
    }
  }

  if (loader) {
    return(<Loader/>)
  }

  // console.log("INDEX", appState)

  return (
    <Layout showLoginButton={!appState.profileUuid.value}>
      <main className="container">
        <div className="debieBusinessInfo">
          <div className="row">
            <div className="col-sm-12">
              <div className="formWrap">
                <div className="row">
                  <div className="col-sm-8">
                    <ul className="nav nav-tabs" id="myTab">
                      <li className="nav-item" >
                        <a className={`nav-link active ${selectedFormIndex >= 0 ? 'range-active' : ''}`} id="home-tab" href="#home" aria-label="home" onClick={(e) => { e.preventDefault(); switchToFormTab(1) }} ></a>
                      </li>
                      <li className="nav-item" >
                        <a className={`nav-link ${selectedFormIndex >= 1 ? 'range-active' : ''}`} id="profile-tab" href="#profile" aria-label="profile" onClick={(e) => {  e.preventDefault(); onboardOneRequest(); }}></a>
                      </li>
                      <li className="nav-item" >
                        <a className={`nav-link ${selectedFormIndex >= 2 ? 'range-active' : ''}`} id="identity-tab" href="#identity" aria-label="identity" onClick={(e) => {  e.preventDefault(); onboardTwoRequest(); }}></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="row">
                      <div className="col-sm-8">
                        <h1>Let’s get started</h1>
                        <form className="formWrap" method="POST" onSubmit={(e) => { e.preventDefault(); onboardOneRequest(); }} >
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="first-name">First Name <span className="required">*</span>
                                  <input type="text" className="form-control" name="first-name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                                  {
                                    firstNameError &&
                                    <span className="error-message">{firstNameError}</span>
                                  }
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="last-name"  >Last Name <span className="required">*</span>
                                  <input type="text" className="form-control" name="last-name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                                  {
                                    lastNameError &&
                                    <span className="error-message">{lastNameError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="legal-b-name">Legal Business Name <span className="required">*</span>
                                  <input type="text" className="form-control" name="legal-b-name" value={legalBusinessName} onChange={(e) => { setLegalBusinessName(e.target.value) }} placeholder="Great Credit, Inc" />
                                  {
                                    legalBusinessNameError &&
                                    <span className="error-message">{legalBusinessNameError}</span>
                                  }
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                {/* <div>
                                  <label className="form-label" htmlFor="b-phone">Business Phone <span className="msg">(Optional)</span>
                                  <input type="number" name="b-phone" className="form-control" value={mobile} onChange={(e) => { setMobile(e.target.value) }} />
                                  {
                                    mobileError &&
                                    <span className="error-message">{mobileError}</span>
                                  }</label>
                                </div> */}
                                <div>
                                  <label className="form-label" htmlFor="trade-name">Trade Name <span className="required">*</span>
                                  <input type="text" name="trade-name" className="form-control" value={tradeName} onChange={(e) => { setTradeName(e.target.value) }} placeholder="Sandra's Tacos" />
                                  {
                                    tradeNameError &&
                                    <span className="error-message">{tradeNameError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="email">Email <span className="required">*</span>
                                  <input type="email" name="email" className="form-control" value={businessEmail} onChange={(e) => { setBusinessEmail(e.target.value) }} />
                                  {
                                    businessEmailError &&
                                    <span className="error-message">{businessEmailError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="">
                                <div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <div className="d-inline d-inline-sm">
                                  <div className="pull-left mr-2 mb-3">
                                    <button type="submit" className="btn nexttab btn-default gtm-onboard-1" >Next</button>
                                    <div className="securityBadge d-flex">
                                      <div>
                                        <img src="images/lock.png" alt="" />
                                      </div>
                                      <div>
                                        <h4>256-Bit Security </h4>
                                        <p>Encrypted</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="increasePoints mb-5 pull-left alert alert-success"> <img src="images/increase-point-arrow.png" alt="" /> Your score can increase by over 50 points in 4-6 months </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-4 text-center">
                        <div className="headingMd">
                          <h2>Did you know?</h2>
                        </div>
                        <div className="infoMessage">
                          <div className="icon">
                            <img src="images/merchant-account.png" alt="" />
                          </div>
                          <p>80% of business owners use personal credit lines because their business credit data is incomplete. Debie fixes this for you.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="row">
                      <div className="col-sm-8">
                        <h1>Business information</h1>
                        <form className="formWrap" method="POST" onSubmit={(e) => { e.preventDefault(); onboardTwoRequest(); }}>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="business-address">Business Address <span className="required">*</span>
                                  <LocationSearchInput
                                    value={address1}
                                    onSelect={(address) => (
                                      setAddress1(address)
                                      // console.log(address)
                                    )}
                                    onChange={(address) => (
                                      // console.log(address)
                                      setAddress1(address)
                                    )}
                                    onReceiveStreet= {(street)=>{setAddress1(street)}}
                                    onReceivePostalCode={(postalCode)=>{setPincode(postalCode)}}
                                    onReceiveCity={(city)=>{setCity(city)}}
                                    onReceiveState={(state)=>{setState(state)}}
                                    inputClassName="form-control"
                                  />
                                  {/* <input type="text" className="form-control" name="business-address" value={address1} onChange={(e) => { setAddress1(e.target.value) }} /> */}
                                  {
                                    address1Error &&
                                    <span className="error-message">{address1Error}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="zip-code">Zip Code <span className="required">*</span>
                                  <input type="text" className="form-control" name="zip-code" value={pincode} onChange={(e) => { setPincode(e.target.value) }} />
                                  {
                                    pincodeError &&
                                    <span className="error-message">{pincodeError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="city">City <span className="required">*</span>
                                  <input type="text" className="form-control" name="city" value={city} onChange={(e) => { setCity(e.target.value) }} />
                                  {
                                    cityError &&
                                    <span className="error-message">{cityError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="state">State <span className="required">*</span>
                                  <input type="text" className="form-control" name="state" value={state} onChange={(e) => { setState(e.target.value) }} />
                                  {
                                    stateError &&
                                    <span className="error-message">{stateError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="b-ein">Unit/Suite # <span className="msg">(Optional)</span></label>
                                  <NumberFormat name="b-ein" className="form-control" mask="_" value={unitNumber} onValueChange={(e) => { setUnitNumber(e.value) }} />
                                  {
                                    unitNumberError &&
                                    <span className="error-message">{unitNumberError}</span>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="b-ein">Business EIN <span className="msg">(Optional)</span></label>
                                  <NumberFormat name="b-ein" className="form-control" format="##-#######" placeholder="XX-XXXXXXX" mask="_" value={einNumber} onValueChange={(e) => { setEinNumber(e.value) }} />
                                  {
                                    businessEinError &&
                                    <span className="error-message">{businessEinError}</span>
                                  }

                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            address1.trim() !== "" &&
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <div className="d-flex justify-content-between align-items-center">

                                    <span className="form-label toggleChkBoxLabel labelLg" htmlFor="radio-one">Does your business have a lease at this address?</span>
                                    <div className="switch-field">
                                      <label htmlFor="radio-two">
                                        <input type="radio" id="radio-two" name="radio-two" value={false} onChange={(e) => { setHasLease(false) }} checked={!hasLease} />
                                        <span>No</span>
                                      </label>
                                      <label htmlFor="radio-one">
                                        <input type="radio" id="radio-one" name="radio-one" value={true} onChange={(e) => { setHasLease(true) }} checked={hasLease} />
                                        <span>Yes</span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          {
                            address1.trim() !== "" && hasLease &&
                            <div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <div>
                                      <span className="form-label labelLg" htmlFor="lease-start-date">A little more info about your lease</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="lease-start-date">Lease Start Date <span className="required">*</span>
                                        <div className="input-group date">
                                          <DatePicker name="lease-start-date" viewMode="years" dateFormat="MM/DD/YYYY" timeFormat={false} closeOnSelect={true} value={startDate} onChange={(e) => { setStartDate(e) }} />
                                          <span className="input-group-addon">
                                            <img src="images/calendar.png" alt="" />
                                          </span>
                                          {
                                            startDateError &&
                                            <span className="error-message">{startDateError}</span>
                                          }
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="monthly-lease-amount">Monthly Lease Amount <span className="required">*</span>
                                      <input type="number" name="monthly-lease-amount" className="form-control" value={leaseAmount} onChange={(e) => { setLeaseAmount(e.target.value) }} />
                                      {
                                        leaseAmountError &&
                                        <span className="error-message">{leaseAmountError}</span>
                                      }</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="lease-owner-name">Property Manager Name
                                        <input type='text' name="lease-owner-name" className="form-control" value={ownerName} onChange={(e) => { setOwnerName(e.target.value) }} />
                                        {
                                          ownerNameError &&
                                          <span className="error-message">{ownerNameError}</span>
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="lease-owner-mobile">Property Manager Phone
                                      <NumberFormat
                                        name="lease-owner-mobile"
                                        className="form-control"
                                        format="(###) ###-####"
                                        placeholder="(XXX) XXX-XXXX"
                                        mask="_"
                                        value={ownerMobile}
                                        onValueChange={(e) => { setOwnerMobile(e.value) }}
                                        />
                                      {
                                        ownerMobileError &&
                                        <span className="error-message">{ownerMobileError}</span>
                                      }</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <div className="d-flex">
                                  <div>
                                    <button type="submit" className="btn nexttab btn-default"  >Next</button>
                                    <div className="securityBadge d-flex">
                                      <div>
                                        <img src="images/lock.png" alt="" />
                                      </div>
                                      <div>
                                        <h4>256-Bit Security </h4>
                                        <p>Encrypted</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-4 text-center">
                        <div className="headingMd">
                          <h2>Did you know?</h2>
                        </div>
                        <div className="infoMessage">
                          <div className="icon">
                            {
                              (() => {
                                const leaseAmountInt = parseInt(leaseAmount.trim());
                                if (isNaN(leaseAmountInt)) {
                                  return (
                                    <img src="images/payment-history.png" alt="" />
                                  )
                                } else {
                                  if (leaseAmountInt <= 2500) {
                                    return (
                                      <img src="images/meter-2500.png" alt="" />
                                    )
                                  } else if (leaseAmountInt <= 5000) {
                                    return (
                                      <img src="images/meter-5000.png" alt="" />
                                    )
                                  } else {
                                    return (
                                      <img src="images/meter-10000.png" alt="" />
                                    )
                                  }
                                }
                              })()
                            }

                          </div>
                          {
                            (() => {
                              const leaseAmountInt = parseInt(leaseAmount.trim());
                              if (isNaN(leaseAmountInt)) {
                                return (
                                  <p>2 out of 10 business owners are currenty getting credit for their lease payment history. Your history can unlock better scores and help remove personal guarantees.</p>
                                )
                              } else {
                                return (
                                  <p>Based on your information, <strong>your score can increase by 40-60 points</strong> in 4-6 months</p>
                                )
                              }
                            })()
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="identity" role="tabpanel" aria-labelledby="identity-tab">
                    <div className="row">
                      <div className="col-sm-8">
                        <h1>Verification</h1>

                        <p>To report accurate data to the Credit Bureaus and boost your score, Debie needs to verify your payment history. Please continue with Quickbooks or your Bank.</p>
                        <form className="formWrap" method="POST" onSubmit={(e) => { e.preventDefault(); onboardFourRequest(); }}>
                          <div className="row">
                            {(bankName||quickbooksDone) && <div className="col-sm-12">
                              <div className="form-group">
                              </div>
                            </div>}
                            <div className="col-sm-6">
                            {bankName && <div className="form-group">
                               <div>
                                 <label className="form-label" htmlFor="bank-name">Bank Name
                                 <span className="required">*</span>
                                   <span className="form-control plaid-link">
                                      {
                                        bankName && appState.institution.value && appState.institution.value.logo &&
                                        <img src={`data:image/png;base64, ${appState.institution.value.logo}`} alt="" />
                                      }
                                      {bankName && <span>{bankName ? bankName : 'Connect a Bank Account'}</span>}
                                      </span>
                                  </label>
                                </div>
                              </div>}
                              {quickbooksDone && <div className="form-group">
                                <div>
                                  <label className="form-label" >
                                        <span>{"Quickbooks Connected !"}</span>
                                  </label>

                                </div>
                              </div>}
                            </div>

                          </div>
                          {!(bankName || quickbooksDone) && <div className="row">
                              <div className="col-sm-6 text-center">
                                <div onClick={handleQuickbooksClick} className="infoMessage infoBank">
                                  <div className="icon iconQuickbooks">
                                    <img src="images/quickbooks.svg" alt="" />
                                  </div>
                                  <p>Debie uses QuickBooks to verify your payment history to report accurate information to the Credit Bureaus. We do not sell your data.</p>
                                  <div id="intuButton" hidden={true}></div>
                                  <button className="btn btn-default gtm-qb">Connect To Quickbooks</button>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <label className="form-label" htmlFor="bank-name">
                                  <div className="infoMessage infoBank text-center">
                                    <div className="icon">
                                      <img src="images/bank.svg" alt="" />
                                    </div>
                                    <p>Your Bank allows Debie verify your payment history to report accurate information to the Credit Bureaus. We do not sell your data.</p>
                                    {
                                      appState.profileUuid.value &&
                                      <PlaidLink
                                        className="gtm-plaid" //form-control plaid-link
                                        clientName='Debie'
                                        countryCodes={['US']}
                                        env={settings.get('GATSBY_PLAID_ENV')}
                                        publicKey='394a4d8c7fa48e7ef8881ec5ac6b8e'
                                        product={['assets']}
                                        language='en'
                                        style={{backgroundColor: '#6BC6A5', borderWidth: '0px', borderRadius: '5px', padding: '15px', color: 'white'}}

                                        onSuccess={(public_token, metadata) => {
                                          getOnboardedAccessTokenRequest(public_token, metadata.institution.institution_id, metadata.institution.name);
                                          setBankName(metadata.institution.name);
                                          plaidGetInstitutionRequest(metadata.institution.institution_id);
                                          // let lease_id = $("#tenant_form").find('input[name="lease_id"]').val();
                                          // url = "/get_access_token/" + lease_id;
                                          // console.log(lease_id)
                                          // $.post(url, {
                                          //     'public_token': public_token,
                                          //     'csrfmiddlewaretoken': '{{ csrf_token }}',
                                          // }).done(function (data, statusText, xhr) {
                                          //     let status = xhr.status;                //200
                                          //     $('#acc-link-msg').removeClass("loader").addClass("acc-link-msg");
                                          //     if (status == 200) {
                                          //         document.getElementById('acc-link-msg').innerHTML = 'Bank account linked Successfully';
                                          //         $('#form-submit').prop('disabled', false);
                                          //         $('#form-submit').removeClass('opacity');
                                          //     }
                                          // });
                                        }}
                                        onExit={(err, metadata) => {
                                          // $('#acc-link-msg').removeClass("loader").addClass("acc-link-msg");
                                          // if (err != null) {
                                          //     // The user encountered a Plaid API error prior to exiting.
                                          // }
                                          // metadata contains information about the institution
                                          // that the user selected and the most recent API request IDs.
                                          // Storing this information can be helpful for support.
                                        }}
                                        onEvent={(eventName, metadata) => {
                                          // this callback as your users connect an Item to Plaid.
                                          // For example:
                                          // eventName = "TRANSITION_VIEW"
                                          // metadata  = {
                                          //   link_session_id: "123-abc",
                                          //   mfa_type:        "questions",
                                          //   timestamp:       "2017-09-14T14:42:19.350Z",
                                          //   view_name:       "MFA",
                                          // }
                                        }}
                                      >
                                        Select Bank
                                      </PlaidLink>
                                    }
                                  </div>
                                </label>
                              </div>
                            </div>}

                          {
                            (bankName||quickbooksDone) &&
                            <>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label labelLg" htmlFor="first-name">Create an account</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="password">Password <span className="required">*</span>
                                      <input type="password" name="password" className="form-control" value={password1} onChange={(e) => { setPassword1(e.target.value) }} />
                                      {
                                        passwordError &&
                                        <span className="error-message">{passwordError}</span>
                                      }</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="re-type-password">Re-type Password <span className="required">*</span>
                                      <input type="password" name="re-type-password" className="form-control" value={password2} onChange={(e) => { setPassword2(e.target.value) }} />
                                      {
                                        passwordError &&
                                        <span className="error-message">{passwordError}</span>
                                      }</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <div className="d-flex">
                                  <div>
                                    <button type="submit" disabled={!(bankName||quickbooksDone)} className="btn btn-disabled nexttab btn-default gtm-onboard-4" >Next</button>
                                    <div className="securityBadge d-flex">
                                      <div>
                                        <img src="images/lock.png" alt="" />
                                      </div>
                                      <div>
                                        <h4>256-Bit Security </h4>
                                        <p>Encrypted</p>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-4 text-center">
                        <div className="headingMd">
                          <h2>For your protection</h2>
                        </div>
                        <div className="infoMessage">
                          <div className="icon">
                            <img src="images/security-business_money_financial_bank_sell.png" alt="" />
                          </div>
                          <p>We use bank-level encryption to protect our users. Your credentials are entered securely and directly with your bank. We do not store this data.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-sm-4">
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )

}
export default IndexPage
